.mat-form-field {
  padding-bottom: 1em;

  //&.required, .required {
  //  .ng-invalid.ng-touched {
  //    .ng-select-container {
  //      border-color: #dc3545;
  //      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
  //    }
  //  }
  //
  //  .ng-invalid.ng-untouched {
  //    .ng-select-container {
  //      border-color: #dc3545;
  //      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
  //    }
  //  }
  //}
  ng-select.ng-select-auto-width {
    .ng-dropdown-panel {
      width: auto !important;
    }
  }

  .ng-dropdown-panel.ng-select-bottom {
    top: calc(100% - 0.25em)
  }

  &:not(.mat-form-field-appearance-legacy) {
    mat-error {
      app-form-error {
        font-size: 75%;
      }
    }

    .ng-select {
      padding-bottom: 0.25em;


      .ng-select-container {
        &.ng-has-value {
          padding-top: 9px;
        }
      }
    }
  }

}

ng-select.dense {
  .ng-select-container {
    min-height: 2.5rem !important;
  }

  .ng-select .ng-select-container .ng-value-container {
    padding: 0;
    border-top: none
  }
}

.mat-form-field.dense {
  color: grey;
  padding: 6px 20px;
  font-size: smaller;
  width: auto;

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-wrapper {
    padding: 0.15rem;
    border-radius: 8px;
    border: thin solid grey;

    .mat-form-field-flex {
      padding: 4px;
      min-height: auto;

      .mat-form-field-infix {
        padding: 0;
        border: none;
      }
    }
  }
}

ng-dropdown-panel.ng-dropdown-panel.ng-select-auto-width {
  width: auto !important;

}


