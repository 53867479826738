[class~=wrap] {
  box-sizing: border-box;
  max-width: 130em;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
[class~=wrap] *,
[class~=wrap] *::before,
[class~=wrap] *::after {
  box-sizing: inherit;
}

[class*=grid] {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin-left: -8px;
  margin-right: -8px;
}

[class*=column] {
  box-sizing: border-box;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 8px 0;
}

[class=column] {
  flex: 1 1 0;
}

[class*=grid][class*=column] {
  margin: 0;
  padding: 0;
}

[class~=grid-no-gutter] {
  margin: 0;
}
[class~=grid-no-gutter] > [class*=column] {
  padding: 0;
}

[class~=grid-center] {
  justify-content: center;
}

[class~=grid-right] {
  justify-content: flex-end;
  align-self: flex-end;
  margin-left: auto;
}

[class~=grid-top] {
  align-items: flex-start;
}

[class~=grid-middle] {
  align-items: center;
}

[class~=grid-stretch] {
  align-items: stretch;
}

[class~=grid-bottom] {
  align-items: flex-end;
}

[class~=grid-reverse] {
  flex-direction: row-reverse;
}

[class~=grid-column] {
  flex-direction: column;
}
[class~=grid-column] > [class*=column] {
  flex-basis: auto;
}

[class~=grid-column-reverse] {
  flex-direction: column-reverse;
}
[class~=grid-column-reverse] > [class*=column] {
  flex-basis: auto;
}

[class~=grid-space-between] {
  justify-content: space-between;
}

[class~=grid-space-around] {
  justify-content: space-around;
}

[class~=grid-equal-height] > [class*=column] {
  display: flex;
}

[class~=grid-no-bottom] > [class*=column] {
  padding-bottom: 0;
}

[class~=column-column] {
  display: flex;
  flex-direction: column;
}

[class~=column-top] {
  align-self: flex-start;
}

[class~=column-middle] {
  align-self: center;
}

[class~=column-bottom] {
  align-self: flex-end;
}

[class~=column-first] {
  order: -1;
}

[class~=column-last] {
  order: 15;
}

[class~=order-1] {
  order: 1;
}

[class~=order-2] {
  order: 2;
}

[class~=order-3] {
  order: 3;
}

[class~=order-4] {
  order: 4;
}

[class~=order-5] {
  order: 5;
}

[class~=order-6] {
  order: 6;
}

[class~=order-7] {
  order: 7;
}

[class~=order-8] {
  order: 8;
}

[class~=order-9] {
  order: 9;
}

[class~=order-10] {
  order: 10;
}

[class~=order-11] {
  order: 11;
}

[class~=order-12] {
  order: 12;
}

[class~=order-13] {
  order: 13;
}

[class~=order-14] {
  order: 14;
}

@media only screen and (min-width: 120em) {
  [class~=order-desktop-1] {
    order: 1;
  }

  [class~=order-desktop-2] {
    order: 2;
  }

  [class~=order-desktop-3] {
    order: 3;
  }

  [class~=order-desktop-4] {
    order: 4;
  }

  [class~=order-desktop-5] {
    order: 5;
  }

  [class~=order-desktop-6] {
    order: 6;
  }

  [class~=order-desktop-7] {
    order: 7;
  }

  [class~=order-desktop-8] {
    order: 8;
  }

  [class~=order-desktop-9] {
    order: 9;
  }

  [class~=order-desktop-10] {
    order: 10;
  }

  [class~=order-desktop-11] {
    order: 11;
  }

  [class~=order-desktop-12] {
    order: 12;
  }

  [class~=order-desktop-13] {
    order: 13;
  }

  [class~=order-desktop-14] {
    order: 14;
  }
}
[class~=off-0] {
  margin-left: 0%;
}

[class~=off-1] {
  margin-left: 7.1428571429%;
}

[class~=off-2] {
  margin-left: 14.2857142857%;
}

[class~=off-3] {
  margin-left: 21.4285714286%;
}

[class~=off-4] {
  margin-left: 28.5714285714%;
}

[class~=off-5] {
  margin-left: 35.7142857143%;
}

[class~=off-6] {
  margin-left: 42.8571428571%;
}

[class~=off-7] {
  margin-left: 50%;
}

[class~=off-8] {
  margin-left: 57.1428571429%;
}

[class~=off-9] {
  margin-left: 64.2857142857%;
}

[class~=off-10] {
  margin-left: 71.4285714286%;
}

[class~=off-11] {
  margin-left: 78.5714285714%;
}

[class~=off-12] {
  margin-left: 85.7142857143%;
}

[class~=off-13] {
  margin-left: 92.8571428571%;
}

@media only screen and (min-width: 120em) {
  [class~=off-desktop-0] {
    margin-left: 0%;
  }

  [class~=off-desktop-1] {
    margin-left: 7.1428571429%;
  }

  [class~=off-desktop-2] {
    margin-left: 14.2857142857%;
  }

  [class~=off-desktop-3] {
    margin-left: 21.4285714286%;
  }

  [class~=off-desktop-4] {
    margin-left: 28.5714285714%;
  }

  [class~=off-desktop-5] {
    margin-left: 35.7142857143%;
  }

  [class~=off-desktop-6] {
    margin-left: 42.8571428571%;
  }

  [class~=off-desktop-7] {
    margin-left: 50%;
  }

  [class~=off-desktop-8] {
    margin-left: 57.1428571429%;
  }

  [class~=off-desktop-9] {
    margin-left: 64.2857142857%;
  }

  [class~=off-desktop-10] {
    margin-left: 71.4285714286%;
  }

  [class~=off-desktop-11] {
    margin-left: 78.5714285714%;
  }

  [class~=off-desktop-12] {
    margin-left: 85.7142857143%;
  }

  [class~=off-desktop-13] {
    margin-left: 92.8571428571%;
  }
}
[class~=grid-1] > [class*=column] {
  flex-basis: 100%;
  max-width: 100%;
}

[class~=grid-2] > [class*=column] {
  flex-basis: 50%;
  max-width: 50%;
}

[class~=grid-3] > [class*=column] {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
}

[class~=grid-4] > [class*=column] {
  flex-basis: 25%;
  max-width: 25%;
}

[class~=grid-5] > [class*=column] {
  flex-basis: 20%;
  max-width: 20%;
}

[class~=grid-6] > [class*=column] {
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
}

[class~=grid-7] > [class*=column] {
  flex-basis: 14.2857142857%;
  max-width: 14.2857142857%;
}

[class~=grid-8] > [class*=column] {
  flex-basis: 12.5%;
  max-width: 12.5%;
}

[class~=grid-9] > [class*=column] {
  flex-basis: 11.1111111111%;
  max-width: 11.1111111111%;
}

[class~=grid-10] > [class*=column] {
  flex-basis: 10%;
  max-width: 10%;
}

[class~=grid-11] > [class*=column] {
  flex-basis: 9.0909090909%;
  max-width: 9.0909090909%;
}

[class~=grid-12] > [class*=column] {
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
}

[class~=grid-13] > [class*=column] {
  flex-basis: 7.6923076923%;
  max-width: 7.6923076923%;
}

[class~=grid-14] > [class*=column] {
  flex-basis: 7.1428571429%;
  max-width: 7.1428571429%;
}

@media only screen and (min-width: 120em) {
  [class~=griddesktop-1] > [class*=column] {
    flex-basis: 100%;
    max-width: 100%;
  }

  [class~=griddesktop-2] > [class*=column] {
    flex-basis: 50%;
    max-width: 50%;
  }

  [class~=griddesktop-3] > [class*=column] {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  [class~=griddesktop-4] > [class*=column] {
    flex-basis: 25%;
    max-width: 25%;
  }

  [class~=griddesktop-5] > [class*=column] {
    flex-basis: 20%;
    max-width: 20%;
  }

  [class~=griddesktop-6] > [class*=column] {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }

  [class~=griddesktop-7] > [class*=column] {
    flex-basis: 14.2857142857%;
    max-width: 14.2857142857%;
  }

  [class~=griddesktop-8] > [class*=column] {
    flex-basis: 12.5%;
    max-width: 12.5%;
  }

  [class~=griddesktop-9] > [class*=column] {
    flex-basis: 11.1111111111%;
    max-width: 11.1111111111%;
  }

  [class~=griddesktop-10] > [class*=column] {
    flex-basis: 10%;
    max-width: 10%;
  }

  [class~=griddesktop-11] > [class*=column] {
    flex-basis: 9.0909090909%;
    max-width: 9.0909090909%;
  }

  [class~=griddesktop-12] > [class*=column] {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }

  [class~=griddesktop-13] > [class*=column] {
    flex-basis: 7.6923076923%;
    max-width: 7.6923076923%;
  }

  [class~=griddesktop-14] > [class*=column] {
    flex-basis: 7.1428571429%;
    max-width: 7.1428571429%;
  }
}
[class*=grid] > [class~=column-auto] {
  flex: 1 1 0;
  max-width: 100%;
}

[class*=grid] > [class~=column-1] {
  flex-basis: 7.1428571429%;
  max-width: 7.1428571429%;
}
[class*=grid] > [class~=column-2] {
  flex-basis: 14.2857142857%;
  max-width: 14.2857142857%;
}
[class*=grid] > [class~=column-3] {
  flex-basis: 21.4285714286%;
  max-width: 21.4285714286%;
}
[class*=grid] > [class~=column-4] {
  flex-basis: 28.5714285714%;
  max-width: 28.5714285714%;
}
[class*=grid] > [class~=column-5] {
  flex-basis: 35.7142857143%;
  max-width: 35.7142857143%;
}
[class*=grid] > [class~=column-6] {
  flex-basis: 42.8571428571%;
  max-width: 42.8571428571%;
}
[class*=grid] > [class~=column-7] {
  flex-basis: 50%;
  max-width: 50%;
}
[class*=grid] > [class~=column-8] {
  flex-basis: 57.1428571429%;
  max-width: 57.1428571429%;
}
[class*=grid] > [class~=column-9] {
  flex-basis: 64.2857142857%;
  max-width: 64.2857142857%;
}
[class*=grid] > [class~=column-10] {
  flex-basis: 71.4285714286%;
  max-width: 71.4285714286%;
}
[class*=grid] > [class~=column-11] {
  flex-basis: 78.5714285714%;
  max-width: 78.5714285714%;
}
[class*=grid] > [class~=column-12] {
  flex-basis: 85.7142857143%;
  max-width: 85.7142857143%;
}
[class*=grid] > [class~=column-13] {
  flex-basis: 92.8571428571%;
  max-width: 92.8571428571%;
}
[class*=grid] > [class~=column-14] {
  flex-basis: 100%;
  max-width: 100%;
}

@media only screen and (min-width: 120em) {
  [class*=grid] > [class~=columndesktop-auto] {
    flex: 1 1 0;
    max-width: 100%;
  }

  [class*=grid] > [class~=columndesktop-1] {
    flex-basis: 7.1428571429%;
    max-width: 7.1428571429%;
  }
  [class*=grid] > [class~=columndesktop-2] {
    flex-basis: 14.2857142857%;
    max-width: 14.2857142857%;
  }
  [class*=grid] > [class~=columndesktop-3] {
    flex-basis: 21.4285714286%;
    max-width: 21.4285714286%;
  }
  [class*=grid] > [class~=columndesktop-4] {
    flex-basis: 28.5714285714%;
    max-width: 28.5714285714%;
  }
  [class*=grid] > [class~=columndesktop-5] {
    flex-basis: 35.7142857143%;
    max-width: 35.7142857143%;
  }
  [class*=grid] > [class~=columndesktop-6] {
    flex-basis: 42.8571428571%;
    max-width: 42.8571428571%;
  }
  [class*=grid] > [class~=columndesktop-7] {
    flex-basis: 50%;
    max-width: 50%;
  }
  [class*=grid] > [class~=columndesktop-8] {
    flex-basis: 57.1428571429%;
    max-width: 57.1428571429%;
  }
  [class*=grid] > [class~=columndesktop-9] {
    flex-basis: 64.2857142857%;
    max-width: 64.2857142857%;
  }
  [class*=grid] > [class~=columndesktop-10] {
    flex-basis: 71.4285714286%;
    max-width: 71.4285714286%;
  }
  [class*=grid] > [class~=columndesktop-11] {
    flex-basis: 78.5714285714%;
    max-width: 78.5714285714%;
  }
  [class*=grid] > [class~=columndesktop-12] {
    flex-basis: 85.7142857143%;
    max-width: 85.7142857143%;
  }
  [class*=grid] > [class~=columndesktop-13] {
    flex-basis: 92.8571428571%;
    max-width: 92.8571428571%;
  }
  [class*=grid] > [class~=columndesktop-14] {
    flex-basis: 100%;
    max-width: 100%;
  }
}