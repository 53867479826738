//Circle
$base-circle-radius: 10px;
%circle-base {
  background-color: #6b6b6b;
  display: inline-block;

}

.circle {
  @extend %circle-base;
  width: $base-circle-radius;
  height: $base-circle-radius;
  border-radius: $base-circle-radius;

  &.small {
    width: 0.75*$base-circle-radius;
    height: 0.75*$base-circle-radius;
    border-radius: 0.75*$base-circle-radius;

  }

  &.large {
    width: 1.5*$base-circle-radius;
    height: 1.5*$base-circle-radius;
    border-radius: 1.5*$base-circle-radius;

  }

  &.red {
    background-color: #db0000;
  }

  &.yellow {
    background-color: #ffc400;
  }

  &.blue {
    background-color: #039be5;
  }

  &.green {
    background-color: #53b260;
  }

}
