
mat-button-toggle-group {
  &.ng-invalid.ng-touched {
    border: solid 1px red;

    .mat-button-toggle, .mat-button-toggle:not(.mat-button-toggle-checked) {
      background-color: lighten(red, 40%);

    }
  }

  &.block {
    width: 100%;

    mat-button-toggle {
      flex: 1 1 100%;
      box-sizing: border-box;
      align-items: center;
      flex-direction: row;
      display: flex;
    }

  }

  &.small {
    .mat-button-toggle-appearance-standard {
      .mat-button-toggle-label-content {
        line-height: 28px;
      }
    }
  }

  &.smaller {
    .mat-button-toggle-appearance-standard {
      .mat-button-toggle-label-content {
        line-height: 24px;
        font-size: smaller;
      }
    }
  }

  .mat-button-toggle:not(.mat-button-toggle-checked) {
    background-color: #f0f0f0;
  }

  .mat-button-toggle.mat-button-toggle-checked {
    &.green-if-checked {
      color: green;
      background-color: lighten(green, 70%);
    }

    &.red-if-checked {
      color: red;
      background-color: lighten(red, 40%);
    }

    background-color: white;
    font-weight: bold;;
  }


}


// Additional Material Colors
button.mat-button, button.mat-flat-button, button.mat-raised-button, button.mat-button-base, .mat-icon {
  border-radius: 25px;

  &.mat-green {
    color: #fff;
    background-color: #45a452;

    &[disabled] {
      background-color: hsl(128, 41%, 76%);
    }
  }


  &.mat-lightgrey {
    color: #fff;
    background-color: lightgrey;

    &[disabled] {
      background-color: lighten(lightgrey, 20%);
    }
  }

  &.mat-grey {
    color: #fff;
    background-color: grey;

    &[disabled] {
      background-color: lighten(grey, 20%);
    }
  }


  &.mat-orange {
    color: #fff;
    background-color: orange;

    &[disabled] {
      background-color: #f5c67d;
    }
  }

  // Light Blue Button

  &.mat-blue {
    color: #fff;
    background-color: #039be5;

    &[disabled] {
      background-color: rgba(3, 155, 229, 0.5);
    }
  }

  // Stark Red Buttons
  &.mat-red {
    color: #fff;
    background-color: #da0000;

    &[disabled] {
      background-color: rgba(218, 0, 0, 0.5);
    }

  }


  // Grey Buttons

  &.mat-gray {
    color: #6e7c89;
    background-color: #e4e5e6;

    &[disabled] {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  // White w/ Outline Buttons

  &.mat-outline {
    background-color: #fff;
    border: 1px solid #666;
  }
}
