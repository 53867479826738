.badge.small {
  font-size: 0.6rem;
}

.badge--express {
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 100%;
  padding: 2px;
  background-color: yellow;

  &.inline {
    border-radius: 10px;
    display: inline-block;
    text-transform: uppercase;
    position: unset;
    background-color: yellow;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 25px;
    pointer-events: none;
    width: auto;

  }

  span {
    position: absolute;
    top: 50%;
    left: -5px;
    line-height: 8px;
    font-weight: bold;
    font-size: 8px;
    transform: rotate(-90deg) translateX(20%);

  }
}

.badge--partner {
  background-color: #039be5;
  border-radius: 10px;
  display: inline-block;
  color: #fff;
  padding-left: 8px;
  padding-right: 8px;
  text-transform: uppercase;
  font-size: 9px;
  letter-spacing: 1px;
}

.badge--vip-disabled {
  border-radius: 10px;
  background-color: transparent;
  color: #fff;
  margin-left: 25px;
  height: 25px;
  display: inline-block;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 25px;
  pointer-events: none;

}

.badge--vip {
  &.disabled {
    background-color: lightgrey;
    border: darken(lightgrey, 20%);
  }

  border-radius: 10px;
  background-color: #c8b485;
  color: #fff;
  margin-left: 25px;
  height: 25px;
  display: inline-block;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 25px;
  pointer-events: none;

  span {
    display: inline-block;
    font-weight: 500;
  }

  .mat-icon {
    font-size: 18px;
    width: 18px;
    height: 18px;
    vertical-align: middle;
    margin-right: 5px;
  }

  &.inline {
    display: inline-block;
    margin: 0;
  }

  &.small {
    height: 20px;
    font-size: 10px;
    line-height: 20px;
  }
}
