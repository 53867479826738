.data-box {
  border-radius: 4px;
  border: thin solid lightgrey;
  margin: 0 6px 18px 6px;
  width: 100%;

  .row {
    border-bottom: thin solid lightgrey;

    &:last-child {
      border-bottom: none;
    }

    .data-block {
      padding: 28px 8px 6px 6px;
      position: relative;
      height: 3.5rem;

      .key {
        position: absolute;
        top: 3px;
        left: 6px;
        color: grey;
        font-size: smaller;
      }

      .value {

      }
    }
  }


}
