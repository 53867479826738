@import "../node_modules/bootstrap/scss/bootstrap";
@import "assets/scss/theme";

$material-design-icons-font-directory-path: "~material-design-icons-iconfont/dist/fonts/";
@import "~material-design-icons-iconfont/src/material-design-icons";
@import "~@ng-select/ng-select/themes/material.theme.css";
@import "~angular-notifier/styles.css";
@import "~intl-tel-input/build/css/intlTelInput.css";


//Widget/Cards
@import "./assets/scss/widget.scss";

//Print
@import "./assets/scss/print.scss";

//dialogs
@import "assets/scss/dialog.scss";


//ng-select
@import "assets/scss/ng-select.scss";

//form-styles
@import "assets/scss/form.scss";

//Material - Table
@import "./assets/scss/table.scss";

// Material - Buttons
@import "./assets/scss/buttons.scss";

//Material - Icons
@import "assets/scss/icons.scss";

// Material - DataBox
@import "./assets/scss/data-box.scss";

//Circle
@import "./assets/scss/circle.scss";

//Action boxes
@import "./assets/scss/action-box.scss";

// badged
@import "assets/scss/badge.scss";


//generator for margins and padding -classes
// TODO: merge into Bootstrap margin/padding
@import "./assets/scss/margin.padding.generator";
$radii: (5, 10, 15, 20, 25);
@each $radius in $radii {
  .border-radius-#{$radius} {
    border-radius: #{$radius}px;
  }
}

//generator for rounded borders
.inline-icon {
  vertical-align: text-top;
  font-size: 18px !important;
}

* {
  font-family: "Roboto", "Menlo", sans-serif;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

$bmoffice24-blue: "#039be5" !default;
$bmoffice24-green: "#45a452" !default;
$bmoffice24-orange: "orange" !default;
$bmoffice24-red: "#da0000" !default;
$bmoffice24-gutter-half: "16px" !default;

.pos-relative {
  position: relative;
}

.on-hover-bg-grey:hover {
  background-color: grey;
}

.on-hover-bg-lightgrey:hover {
  background-color: lightgrey;
}

.ng-select.ng-invalid.ng-touched .ng-select-container {
  border: 2px solid #f44336;
}

main.authenticated {
  display: flex;
  //min-height: 100vh;
  margin: 0 auto;
  flex-direction: column;
  background-color: #e4e5e6;

  > header {
    // background-color: #fff;
    // filter: invert(1) contrast(0.55) saturate(0);

    .mainmn__outer {
      // filter: invert(1) contrast(0.55) saturate(0);
    }
  }

  main {
    // dark mode
    // filter: invert(1) contrast(0.55) saturate(0);
    min-height: 5em;
    flex: 1;
  }
}

// Icon Styling

.grey-icon {
  color: #6b6b6b;
}

.white-icon svg {
  fill: white;
}

authenticated-app-shell:not(.debug) {
  pre {
    display: none !important;
  }
}

// Placeholders et al

p.placeholder {
  padding-left: 24px;
  line-height: 48px;
  height: 48px;
  margin-bottom: 0;
  background-color: white;
}

i {
  border: 5px solid coral !important;
  text-decoration-style: dashed !important;
  font-style: italic !important;
  font-family: monospace !important;
  line-height: 17px !important;
  border-radius: 5px !important;
  display: block;
  margin-top: 24px !important;
  margin-bottom: 24px !important;
  width: 100%;

  background-image: linear-gradient(
      135deg,
      rgba(0, 0, 0, 0.03) 25%,
      transparent 25%,
      transparent 50%,
      rgba(0, 0, 0, 0.03) 50%,
      rgba(0, 0, 0, 0.03) 75%,
      transparent 75%,
      transparent
  );
  background-color: #fafcfd;
  background-size: 20px 20px;
  padding: 24px;
}

pre {
  font-family: monospace;
  line-height: 17px;
  border-radius: 5px;
  // color: #3d3d3d;
  // background-color: #ffffff;
  color: #e7e19c;
  background-color: #282a36;
  font-weight: 700;
  padding: 15px 5px;
  max-height: 65vh;
  overflow-y: scroll;
  font-size: 17px;

  // font-family: monospace;
  // line-height: 15px;
  // border-radius: 5px;
  // color: #e7e19c;
  // background-color: #282a36;
  // padding: 15px 5px;
  // max-height: 65vh;
  // overflow-y: scroll;
  // font-size: 13px;

  &[cdkdrag] {
    cursor: move;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  &::-webkit-scrollbar {
    max-width: 5px;
    max-height: 5px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    max-height: 5px;
    background-color: #039be5;
  }
}

//  Quick and dirty styles; move to directives in the future
.card-relative {
  mat-dialog-container {
    position: relative;
  }
}

.card {
  background-color: #fff;
  border-radius: 5px;
  /*overflow: clip;*/
  border: 1px solid #ababab;

  &.comment-form-header {
    margin-bottom: -5px;
    position: relative;
    z-index: 1;
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card__heading {
    background-color: #f5f5f5;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    height: 48px;
    padding-left: 24px;
    padding-right: 24px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #8a8a8a;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      text-transform: uppercase;
    }

    .btn--edit {
      flex-grow: 2;
      text-align: right;
    }
  }

  .plus-icon {
    mat-icon {
      color: #fff;
      background-color: #45a352;

      transform: scale(0.85);
      border-radius: 50%;
      vertical-align: middle;
      margin-right: 8px;
    }
  }

  .card__footer {
    background-color: #f0f0f0;
    height: 60px;
    line-height: 60px;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

// Forms
input[type="number"] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

form {
  .submit-grid {
    hr {
      margin-bottom: 25px;
      margin-top: 25px;
      border: 0;
      height: 1px;
      background-color: #dedede;
    }

    .btn--cancel {
      margin-right: 15px;
    }
  }
}

:not(.mat-form-field-hide-placeholder) {
  .mat-form-field-wrapper:not(:has(.mat-form-field-empty)) {
    .mat-form-field-flex {
      padding-top: 9px;
    }
  }
}

.mat-form-field {
  width: 100%;
}

.mat-form-field-flex,
.ng-select-container {
  background-color: #f5f5f5;
  padding-left: 10px;
  min-height: 50px;
}

.mat-paginator-container .mat-form-field-flex {
  min-height: 0;
  background-color: inherit;
  padding-left: inherit;
}

.mat-tab-label-active {
  background-color: #fff;
}

.mat-form-field.white,
ng-select.white {
  .mat-form-field-flex,
  .ng-select-container {
    background-color: #fff;
  }
}


// Badges

// Lists

dl {
  &.list {
    &:after,
      /* flex:0 used to solve issue where the pseudo elements had flex applied in webkit browsers and therefore were taking up space */
    &:before {
      content: "";
      display: table;
      -webkit-flex: 0;
      flex: 0;
    }

    &:after {
      clear: both;
    }

    & > dt {
      float: right;
      clear: both;
      font-weight: 700;
      width: 140px;
      margin-right: -140px;
      position: relative;
      left: -100%;
    }

    & > dd {
      float: left;
      clear: left;
      margin-left: 150px;
    }
  }
}

// "Heroboxes"

div[data-box-style="white"],
textarea[data-box-style="white"] {
  background-color: #fff;
  border: 1px solid #cfcfcf;
}

div[data-box-style="yellow"],
textarea[data-box-style="yellow"] {
  background-color: #ffffe0;
  border: 1px solid #d5d5bc;
}

div[data-box-style="gray"],
textarea[data-box-style="gray"] {
  background-color: #f0f0f0;
  border: 1px solid #cfcfcf;
}

div[data-box-style="green"],
textarea[data-box-style="green"] {
  background-color: #d1ebcd;
  border: 1px solid #b4cbb1;
}

div[data-box-style="blue"],
textarea[data-box-style="blue"] {
  background-color: #dff3ff;
  border: 1px solid #b9c9d3;
}

div[data-box-style="orange"],
textarea[data-box-style="orange"] {
  background-color: #ffeedf;
  border: 1px solid #dccdc0;
}

div[data-box-style="red"],
textarea[data-box-style="red"] {
  background-color: #ffdfea;
  border: 1px solid #dcc0ca;
}

// Fix cdkDrag elements in MatDialogs; offset is messed up there
// see: https://github.com/angular/components/issues/15880
html.cdk-global-scrollblock {
  position: initial !important;
}

html.cdk-global-scrollblock body {
  // position: fixed;
}

// Dialog Modals (panel class)

.modal--ca-form,
.modal--cc-form {
  .mat-dialog-container {
    padding-top: 0;

    .card__heading {
      margin-left: -24px;
      margin-right: -24px;
      margin-bottom: 18px;
    }
  }
}

.no-padding {
  .mat-dialog-container {
    padding: 0;
  }

  .mat-dialog-actions {
    margin-bottom: 0;
  }

  .mat-dialog-content {
    margin: 0 -17px;
  }
}

.dialog--binding-order-form {
  .mat-dialog-content {
    max-height: 85vh;
  }

  .mat-dialog-container {
    max-width: 1680px;
  }
}

.dialog--ticket-prices {
  .mat-dialog-container {
    max-width: 540px;
  }
}

.modal--pod-form .mat-dialog-container {
  padding-top: 0;
  padding-bottom: 0;
}

.mat-dialog-content.dialog--pod-form {
  padding-left: 0;
  padding-right: 0;
}

.dialog--feedback-request {
  width: 730px;
  // padding: 15px;

  .dialog__header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #f6f6f6;
    padding-left: 16px;
    padding-right: 16px;
    border: 1px solid #ddd;
    border-bottom: 0;
    height: 41px;
    line-height: 41px;

    span {
      float: left;
      font-size: 12px;
      font-family: "Consolas", monospace;
    }
  }

  .dialog__original-content {
    background-color: #f6f6f6;
    border: 1px solid #ddd;
    margin-bottom: 35px;
    padding: 15px;
    border-radius: 0 0 5px 5px;

    p,
    li {
      opacity: 0.6;
      font-size: 10px;
      line-height: 14px;
      user-select: none;
      font-family: "Consolas", monospace;
    }
  }

  .dialog__editor {
    .preselect {
      .mat-icon {
        width: 18px;
        height: 18px;
        font-size: 18px;
      }

      &.red {
        .mat-icon {
          color: #db0000;
        }
      }

      &.green {
        .mat-icon {
          color: #45a452;
        }
      }
    }

    .ql-toolbar {
      text-align: right;
      border-bottom: 0;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: #f6f6f6;
      border-color: #ddd;
      padding: 8px 16px;

      .ql-heading {
        float: left;
        font-size: 12px;
        font-family: "Consolas", monospace;
      }

      &.ql-snow .ql-formats:last-of-type {
        margin-right: 0;
      }

      &.ql-snow + .ql-container.ql-snow {
        border: 1px solid #ddd;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    .ql-container {
      background-color: #f6f6f6;
    }

    .ql-editor {
      min-height: 100px;

      &.ql-blank {
        &:before {
          font-style: normal;
        }
      }
    }
  }
}

.dialog--customer-form {
  max-width: 400px;
}

.dialog--stock-item {
  .mat-dialog-content {
    max-width: 650px;
    min-height: 650px;
  }
}

.dialog--edit-order {
  .mat-dialog-content {
    max-width: 840px;
    max-height: none;
    overflow: initial;
  }
}

.dialog--edit-order-storage-system {
  .mat-dialog-content {
    max-width: 840px;
    max-height: none;
    overflow: initial;
  }
}

.dialog--prepare-shipment {
  // max-width: 840px !important;
  max-width: 1024px !important;
  width: 100%;

  .dialog__header {
  }

  .mat-dialog-content {
    min-height: 450px;
  }

  .mat-dialog-container {
  }

  .mini-table {
    margin-left: 0;
    margin-right: 0;
  }

  .table-header {
    .item {
      background-color: #f0f0f0;
      height: 50px;
      line-height: 50px;
      color: #949494;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  .table-row {
    padding-top: 24px;
    padding-bottom: 24px;
    // border-bottom: 1px solid #ebebeb;

    &:nth-of-type(odd) {
      background-color: #f9f9f9;
    }
  }

  .cell {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid #e6e6e6;
  }
}

.dialog--data-medium {
  // max-width: 650px;
  min-height: 650px;
}

.mat-dialog-content.dialog--order-form {
  // min-width: 100%;
  // max-width: 600px;
  max-width: 1080px;
  // min-height: 87vh;
  max-height: none;
}

.dialog--utm {
  min-height: 380px;
  max-width: 420px;

  .dialog__header {
    margin-bottom: 24px;

    span {
      font-size: 18px;
    }
  }

  .dialog__content {
    dl {
    }

    dt {
      font-weight: 700;
      margin-bottom: 12px;
    }

    dd {
      margin-bottom: 12px;
    }
  }
}

.dialog--comment {
  .mat-dialog-container {
    min-width: 600px;
  }
}

.dialog--select-customer {
  min-width: 600px;
  min-height: 475px;

  .dialog__header {
    margin-bottom: 16px;

    span {
      font-size: 18px;
    }
  }

  .mat-dialog-actions {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    border-top: 1px solid #d3d3d3;
  }

  .available-customers__results {
    .ng-dropdown-panel-items {
      max-height: 360px;
    }

    ng-dropdown-panel {
      height: 360px;
      box-shadow: none;

      .scroll-host {
        // overflow: visible;
      }
    }
  }
}

/* Core, IE 7- fixes to ensure the content is readable */
dl.list {
  display: inline-block;
}

dl.list {
  display: block;
}

dl.list > dt {
  float: none;
  position: static;
}

/* List Layout */
dl.list > dt {
  width: 170px;
  margin-right: -170px;
}

dl.list > dd {
  margin-left: 180px;
}

dl.list--dl > dt {
  font-weight: 700;
  margin-bottom: 6px;
}

@media all and (min-width: 1px) {
  dl.list > dt {
    width: 36%;
    margin-right: -36%;
  }
  dl.list > dd {
    margin-left: 38%;
  }
}

@media all and (min-width: 600px) {
  dl.list > dt {
    width: 170px;
    margin-right: -170px;
  }
  dl.list > dd {
    margin-left: 180px;
  }
}

/* List Spacing */
dl.list > dt {
  margin-bottom: 0;
}

dl.list > dd {
  margin-bottom: 0;
}

dl.list > dt:first-child + dd {
  margin-top: 0;
}

dl.list > dd + dt {
  margin-top: 5px;
}

dl.list > dt + dd {
  margin-top: 5px;
}

dl.list dl.list {
  margin-top: 5px;
}

dl.list dl {
  margin-bottom: 0;
}

// Helper classes
.img--resp {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.s-pt--15 {
  padding-top: 15px !important;
}

// Text Align

.m-ta--0,
.m-ta--1,
.m-ta--2 {
}

.m-ta--1 {
  text-align: center;
}

.m-ta--2 {
  text-align: right !important;
}

p a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

[hidden],
.hidden {
  display: none !important;
}

.herobox {
  // box-shadow: 0 5px 10px -3px rgba(0, 0, 0, .07), 0 2px 3px -1px rgba(0, 0, 0, .05);
  padding: 12px;
  background-color: #debaba;
  // margin-bottom: 22px;
  border-radius: 5px;
}

// Animations

.anmt--shake {
  animation: shake 0.62s ease-out both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

// Normalize

dt,
dd {
  margin-left: 0;
}

// Fake Backgrounds

.fake-bg--black,
.fake-bg--slg,
.fake-bg--sllg,
.fake-bg--lg,
.fake-bg {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 0;
    left: -9999px;
    right: 0;
    pointer-events: none;
  }

  &.fake-bg--none {
    position: initial;

    &:before,
    &:after {
      display: none;
    }
  }
}

.fake-bg--black {
  background-color: #000;

  &:before,
  &:after {
    border-left: 9999px solid #000;
    box-shadow: 9999px 0 0 #000;
  }
}

// slg = super light grey
.fake-bg--slg {
  background-color: #f9f9f9;

  &:before,
  &:after {
    border-left: 9999px solid #f9f9f9;
    box-shadow: 9999px 0 0 #f9f9f9;
  }
}

// sllg = slightly light grey
.fake-bg--sllg {
  background-color: #f5f5f5;

  &:before,
  &:after {
    border-left: 9999px solid #f5f5f5;
    box-shadow: 9999px 0 0 #f5f5f5;
  }
}

// lg = light grey
.fake-bg--lg {
  background-color: #f2f2f2;

  &:before,
  &:after {
    border-left: 9999px solid #f2f2f2;
    box-shadow: 9999px 0 0 #f2f2f2;
  }
}

// Headings

.heading--h1,
.heading--h2,
.heading--h3 {
  display: inline-block;
  vertical-align: sub;
  font-weight: 500;
}

.heading--h1 {
  font-size: 23px;
  margin-bottom: 20px;
  line-height: 1.6em;
}

.heading--h2 {
  font-size: 20px;
  // line-height: 32px;
  margin-bottom: 16px;
  line-height: 1.6em;
}

.heading--h3 {
  font-size: 16px;
  margin-bottom: 12px;
  line-height: 1.6em;
}

//float helpers
.float-left {
  float: left;
}

.float-right {
  float: right;
}

//self-align
.pull-right {
  align-self: end;
}

.pre-like {
  white-space: pre-wrap; /* CSS3 browsers  */
  white-space: -moz-pre-wrap !important; /* 1999+ Mozilla  */
  white-space: -pre-wrap; /* Opera 4 thru 6 */
  white-space: -o-pre-wrap; /* Opera 7 and up */
  word-wrap: break-word; /* IE 5.5+ and up */

}

//text align
.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

//Text colors
.text-color-red {
  color: red !important;
}

.text-color-orange {
  color: orange !important;
}

.text-color-green {
  color: green !important;
}

.text-color-blue {
  color: blue !important;
}

.text-color-darkgrey {
  color: #333333 !important;
}

.text-color-lightgrey {
  color: lightgrey !important;
}

.text-color-grey {
  color: gray !important;
}

.text-color-dark-grey {
  color: #333333 !important;
}
.text-color-black {
  color: black !important;
}

// text-bold
.text-bold, .text-strong {
  font-weight: bold !important;
}

.text-small {
  font-size: small;
}

.text-smaller {
  font-size: smaller;
}

.text-large {
  font-size: large;
}

.text-larger {
  font-size: larger;
}

.text-strike {
  text-decoration: line-through;
}

.text-bg-red {
  background-color: red !important;
}

.text-bg-green {
  background-color: green;
}

.text-bg-grey {
  background-color: grey;
}

// coursor helper
.cursor-pointer {
  cursor: pointer;
}

.cursor-block {
  cursor: not-allowed;
}

.cursor-drag {
  cursor: move;
}

.cursor-default {
  cursor: default;
}

// Drag Drop Styling (globally due to ViewEncapsulation)
.cdk-drag-preview[cdkdragboundary=".invoice-items-table"] {
  background-color: #fff;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

// Global Styles for Views
.leads {
  .editor--lead-text {
    .ql-toolbar,
    .ql-container {
      &.ql-snow {
        border: 0;
      }
    }
  }
}

//Spacer
.spacer {
  flex-grow: 1 !important;
}

// Generic Table Styling

.mat-table.cdk-table.table {
  width: 100%;

  &.table--striped {
    table-layout: fixed;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid #ababab;

    &.has-paginator {
      margin-bottom: -1px;
    }

    thead {
      th {
        background-color: #f0f0f0;
        text-transform: uppercase;

        &:first-of-type {
          border-top-left-radius: 5px;
        }

        &:last-of-type {
          border-top-right-radius: 5px;
        }
      }
    }

    tbody {
      tr {
        td {
          border: 0;
        }

        &:nth-of-type(even) {
          background-color: #f9f9f9;
        }
      }
    }
  }

  .dot {
    border-radius: 50%;
    height: 10px;
    display: block;
    width: 10px;
    background-color: #bfbfbf;

    &.green {
      background-color: #45a452;
    }
  }
}

main {
  &.administrators,
  &.customers,
  &.tickets,
  &.invoices,
  &.warehouse,
  &.orders {
    .mat-paginator,
    .mat-paginator-container {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .mat-paginator-container {
      // border: 1px solid #ababab;
      border-top: 0;
    }
  }
}

// Text-editor quill
quill-editor {
  .mat-body p, .mat-body-1 p, .mat-typography p {
    margin: 0 !important;
  }
}

