.cdk-overlay-pane {
  &.width-95 {
    width: 95%;
  }

  &.height-95 {
    height: 95%;
  }

}
