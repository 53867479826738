$spaceamounts: (0, 4, 8, 16, 24, 25, 32, 48, 64);
$sides: (all, x, y, top, bottom, left, right);
$types: (margin, padding);

@each $type in $types {
  @each $side in $sides {
    @each $space in $spaceamounts {
      $prefix: #{str-slice($type, 0, 1)};
      $property-prefix: #{str-slice($side, 0, 1)};
      @if $side == 'all' {
        .#{$prefix}-#{$property-prefix}--#{$space} {
          #{$type}: #{$space}px !important;
        }
      } @else if $side == 'x' or $side == 'y' {
        .#{$prefix}-#{$side}--#{$space} {

          @if $side == 'x' {
            #{$type}-left: #{$space}px !important;
            #{$type}-right: #{$space}px !important;
          } @else {
            #{$type}-top: #{$space}px !important;
            #{$type}-bottom: #{$space}px !important;
          }
        }
      } @else {
        .#{$prefix}-#{$property-prefix}--#{$space} {
          #{$type}-#{$side}: #{$space}px !important;
        }
      }
    }
  }
}
