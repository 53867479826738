// ====================================================================
// =media queries
// ====================================================================

@mixin gy-mq($min: n, $max: n) {
  @if ($min == custom) {
    @media only screen and #{$max} {
      @content;
    }
  } @else if ($min != n and $max == n) {
    @media only screen and (min-width: $min) {
      @content;
    }
  } @else if ($min == n and $max != n) {
    @media only screen and (max-width: $max - 1) {
      @content;
    }
  } @else if ($min != n and $max != n) {
    @media only screen and (min-width: $min) and (max-width: $max - 1) {
      @content;
    }
  }
}



// ====================================================================
// =map set
// ====================================================================

// a map-set function (not included with Sass)
// http://oddbird.net/2013/10/19/map-merge/
@function gy-map-set($map, $key, $value) {
  $new: ($key: $value);
  @return map-merge($map, $new);
}



// ====================================================================
// =base
// ====================================================================

@mixin gy-base($gyMap) {
  // get vars
  $prefix: map-get($gyMap, prefix);
  $wrapName: map-get($gyMap, wrapName);
  $wrapWidth: map-get($gyMap, wrapWidth);
  $gridName: map-get($gyMap, gridName);
  $colName: map-get($gyMap, colName);
  $gutter: map-get($gyMap, gutter);
  $colBottom: map-get($gyMap, colBottom);

  [class~="#{$wrapName}"] {
    box-sizing: border-box;
    max-width: $wrapWidth;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: $gutter;
    padding-right: $gutter;
    *,
    *::before,
    *::after {
      box-sizing: inherit;
    }
  }
  [class*="#{$gridName}"] {
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    margin-left: (-$gutter/2);
    margin-right: (-$gutter/2);
  }
  [class*="#{$colName}"] {
    box-sizing: border-box;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 ($gutter/2) $colBottom;
  }
  // if it is supposed to be auto or single column 100%
  [class="#{$colName}"] {
    flex: 1 1 0;
  }
  // remove margin when nested
  [class*="#{$gridName}"][class*="#{$colName}"] {
    margin: 0;
    padding: 0;
  }
}



// ====================================================================
// =grid helpers
// ====================================================================

@mixin gy-grid-helpers($gyMap) {
  // ger vars
  $prefix: map-get($gyMap, prefix);
  $gridName: map-get($gyMap, gridName);
  $colName: map-get($gyMap, colName);
  $helperPrefix: map-get($gyMap, helperPrefix);

  // no spacing between cols : noGutter
  [class~="#{$gridName}-no-gutter"] {
    margin: 0;
    > [class*="#{$colName}"] {
      padding: 0;
    }
  }

  // horizontal alignment
  [class~="#{$gridName}-center"] {
    justify-content: center;
  }
  [class~="#{$gridName}-right"] {
    justify-content: flex-end;
    align-self: flex-end;
    margin-left: auto;
  }
  // vertical alignment
  [class~="#{$gridName}-top"] {
    align-items: flex-start;
  }
  [class~="#{$gridName}-middle"] {
    align-items: center;
  }
  [class~="#{$gridName}-stretch"] {
    align-items: stretch;
  }
  [class~="#{$gridName}-bottom"] {
    align-items: flex-end;
  }

  // orders
  [class~="#{$gridName}-reverse"] {
    flex-direction: row-reverse;
  }
  [class~="#{$gridName}-column"] {
    flex-direction: column;
    > [class*="#{$colName}"] {
      flex-basis: auto;
    }
  }
  [class~="#{$gridName}-column-reverse"] {
    flex-direction: column-reverse;
    > [class*="#{$colName}"] {
      flex-basis: auto;
    }
  }

  // spaces between and around cols
  [class~="#{$gridName}-space-between"] {
    justify-content: space-between;
  }
  [class~="#{$gridName}-space-around"] {
    justify-content: space-around;
  }

  // equal heights columns
  [class~="#{$gridName}-equal-height"] > [class*="#{$colName}"] {
    display: flex;
  }
  // removes the padding-bottom
  [class~="#{$gridName}-no-bottom"] > [class*="#{$colName}"] {
    padding-bottom: 0;
  }
}



// ====================================================================
// =columns helpers
// ====================================================================

@mixin gy-col-helpers($gyMap) {
  // ger vars
  $prefix: map-get($gyMap, prefix);
  $colName: map-get($gyMap, colName);
  $colTot: map-get($gyMap, colTot);
  $helperPrefix: map-get($gyMap, helperPrefix);

  [class~="#{$colName}-column"] {
    display: flex;
    flex-direction: column;
  }
  [class~="#{$colName}-top"] {
    align-self: flex-start;
  }
  [class~="#{$colName}-middle"] {
    align-self: center;
  }
  [class~="#{$colName}-bottom"] {
    align-self: flex-end;
  }
  [class~="#{$colName}-first"] {
    order: -1;
  }
  [class~="#{$colName}-last"] {
    order: 1 + $colTot;
  }
}



// ====================================================================
// =order helpers
// ====================================================================

@mixin gy-order-helper($gyMap) {
  // get vars
  $prefix: map-get($gyMap, prefix);
  $orderName: map-get($gyMap, orderName);
  $colTot: map-get($gyMap, colTot);
  $helperPrefix: map-get($gyMap, helperPrefix);

  @for $i from 1 through ($colTot) {
    [class~="#{$orderName}-#{$i}"] {
      order: $i;
    }
  }
}

@mixin gy-order-helpers($gyMap) {
  // get vars
  $breakpoint: map-get($gyMap, bp);
  $prefix: map-get($gyMap, prefix);
  $orderName: map-get($gyMap, orderName);
  $colTot: map-get($gyMap, colTot);

  // generate offsets
  @include gy-order-helper($gyMap);
  @each $bp, $bpValue in $breakpoint {
    @include gy-mq($bpValue) {
      $gyInternalMap: gy-map-set($gyMap, orderName, #{$orderName}-#{$bp});
      @include gy-order-helper($gyInternalMap);
    }
  }
}



// ====================================================================
// =offset helpers
// ====================================================================

@mixin gy-offset-helper($gyMap) {
  // get vars
  $prefix: map-get($gyMap, prefix);
  $offsetName: map-get($gyMap, offsetName);
  $colTot: map-get($gyMap, colTot);
  $helperPrefix: map-get($gyMap, helperPrefix);

  @for $i from 0 through ($colTot)-1 {
    [class~="#{$offsetName}-#{$i}"] {
      margin-left: (100%/$colTot)*$i;
    }
  }
}

@mixin gy-offset-helpers($gyMap) {
  // get vars
  $breakpoint: map-get($gyMap, bp);
  $prefix: map-get($gyMap, prefix);
  $offsetName: map-get($gyMap, offsetName);
  $colTot: map-get($gyMap, colTot);

  // generate offsets
  @include gy-offset-helper($gyMap);
  @each $bp, $bpValue in $breakpoint {
    @include gy-mq($bpValue) {
      $gyInternalMap: gy-map-set($gyMap, offsetName, #{$offsetName}-#{$bp});
      @include gy-offset-helper($gyInternalMap);
    }
  }
}



// ====================================================================
// =grid
// ====================================================================

@mixin gy-make-grid($gyMap) {
  // get vars
  $prefix: map-get($gyMap, prefix);
  $gridName: map-get($gyMap, gridName);
  $colName: map-get($gyMap, colName);
  $colTot: map-get($gyMap, colTot);

  @for $i from 1 through $colTot {
    [class~="#{$gridName}-#{$i}"] > [class*='#{$colName}'] {
      flex-basis: (100%/$i);
      max-width: (100%/$i);
    }
  }
}

@mixin gy-make-grids($gyMap) {
  // get vars
  $bpDivider: map-get($gyMap, bpDivider);
  $breakpoint: map-get($gyMap, bp);
  $prefix: map-get($gyMap, prefix);
  $gridName: map-get($gyMap, gridName);
  $colName: map-get($gyMap, colName);
  $colTot: map-get($gyMap, colTot);

  // generate grids
  @include gy-make-grid($gyMap);
  @each $bp, $bpValue in $breakpoint {
    @include gy-mq($bpValue) {
      $gyInternalMap: gy-map-set($gyMap, gridName, #{$gridName}#{$bpDivider}#{$bp});
      @include gy-make-grid($gyInternalMap);
    }
  }
}



// ====================================================================
// =columns
// ====================================================================

@mixin gy-make-col($gyMap) {
  // get vars
  $prefix: map-get($gyMap, prefix);
  $gridName: map-get($gyMap, gridName);
  $colName: map-get($gyMap, colName);
  $colTot: map-get($gyMap, colTot);

  // auto
  [class*="#{$gridName}"] {
    > [class~='#{$colName}-auto'] {
      flex: 1 1 0;
      max-width: 100%;
    }
  }
  [class*="#{$gridName}"] {
    @for $i from 1 through $colTot {
      > [class~='#{$colName}-#{$i}'] {
        flex-basis: (100%/$colTot)*$i;
        max-width: (100%/$colTot)*$i;
      }
    }
  }
}

@mixin gy-make-cols($gyMap) {
  // get vars
  $bpDivider: map-get($gyMap, bpDivider);
  $breakpoint: map-get($gyMap, bp);
  $prefix: map-get($gyMap, prefix);
  $gridName: map-get($gyMap, gridName);
  $colName: map-get($gyMap, colName);
  $colTot: map-get($gyMap, colTot);

  // generate columns
  @include gy-make-col($gyMap);
  @each $bp, $bpValue in $breakpoint {
    @include gy-mq($bpValue) {
      $gyInternalMap: gy-map-set($gyMap, colName, #{$colName}#{$bpDivider}#{$bp});
      @include gy-make-col($gyInternalMap);
    }
  }
}



// ====================================================================
// =custom gutter
// ====================================================================

@mixin gy-gutter($gyMap, $gutterSize, $gutter, $bp: '') {
  $helperPrefix: map-get($gyMap, helperPrefix);
  $prefix: map-get($gyMap, prefix);
  $gridName: map-get($gyMap, gridName);
  $colName: map-get($gyMap, colName);

  $bp: if($bp != '', '-#{$bp}', '');
  $gutterSize: if($gutterSize != '', '-#{$gutterSize}-', '-');
  [class~="#{$gridName}#{$gutterSize}gutter#{$bp}"][class*="#{$gridName}"] {
    margin-left: -$gutter;
    margin-right: -$gutter;
    > [class*="#{$colName}"] {
      padding-left: $gutter;
      padding-right: $gutter;
    }
  }
}

@mixin gy-gutters($gyMap, $gutterSize, $gutter) {
  $breakpoint: map-get($gyMap, bp);
  @each $bp, $bpValue in $breakpoint {
    @include gy-gutter($gyMap, $gutterSize, $gutter);
    @include gy-mq($bpValue) {
      @include gy-gutter($gyMap, $gutterSize, $gutter, $bp);
    }
  }
}



// ====================================================================
// =generate all
// ====================================================================

@mixin gy-generator($gyMap) {
  // base
  @include gy-base($gyMap);

  // helpers
  @include gy-grid-helpers($gyMap);
  @include gy-col-helpers($gyMap);
  @include gy-order-helpers($gyMap);
  @include gy-offset-helpers($gyMap);

  // grid
  @include gy-make-grids($gyMap);
  @include gy-make-cols($gyMap);
}
