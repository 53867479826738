$widget-border-radius: 8px;
$widget-header-color: #f5f5f5;
$widget-footer-color: #f5f5f5;
$widget-border: thin solid #bfbfbf;
.widget {
  border-radius: $widget-border-radius;
  background-color: #ffffff;
  border: $widget-border;

  .widget_header {
    background-color: $widget-header-color;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding-left: 24px;
    border-top-left-radius: $widget-border-radius;
    border-top-right-radius: $widget-border-radius;

    > span {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #8a8a8a;
      text-transform: uppercase;
    }

    mat-button-toggle-group {

      mat-button-toggle {
        .mat-button-toggle-label-content {
          line-height: 21px;
          padding: 2px 16px;
        }
      }
    }

  }

  .widget_content {
    background-color: #ffffff;
    height: auto;
  }

  .widget_footer {
    background-color: $widget-footer-color;
    // height: 60px;
    overflow: clip;
    line-height: 60px;
    border-bottom-left-radius: $widget-border-radius;
    border-bottom-right-radius: $widget-border-radius;

  }


}
