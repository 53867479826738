$row-background-primary: #ffffff;
$row-background-secondary: #f9f9f9;
$row-background-hover: darken($row-background-primary, 20%);
$row-height: 48px;
$row-dense-height: 32px;
$table-cell-padding-y: 1rem;
$table-cell-padding-x: 0.5rem;

.bmo-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  &.bmo-table-bordered {
    border: thin solid grey;
  }

  &.bmo-table-rounded {
    border-radius: 7px;
    overflow: hidden;
  }

  &.rounded-top {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    overflow: hidden;
  }

  &.bmo-table-clickable tbody td {
    cursor: pointer;
  }

  tbody .clickable {
    cursor: pointer;
  }

  &.bmo-table-heading-uppercase thead tr {
    text-transform: uppercase;
  }

  tbody > tr {
    height: $row-height;

    &.dense {
      height: $row-height*0.75;
    }

    td {
      padding: $table-cell-padding-y $table-cell-padding-x;

      &.width-30 {
        width: 30px;
      }

      &.width-50 {
        width: 50px;
      }

      &.width-100 {
        width: 100px;
      }
    }
  }

  thead {
    tr {
      background-color: #f0f0f0;
      height: $row-height;
      color: gray;

      td, th {
        padding: $table-cell-padding-y $table-cell-padding-x;

      }
    }
  }

  tbody {
    tr:nth-child(even) {
      background-color: $row-background-secondary;
    }

    tr:nth-child(odd) {
      background-color: $row-background-primary;
    }
  }

  &.bmo-table-hover tbody tr:hover {
    .text-color-lightgrey {
      color: white;
    }

    background-color: $row-background-hover !important;
  }

  &.bmo-table-dense {
    tr {
      height: $row-dense-height;
    }

    td {
      padding: (0.5 * $table-cell-padding-y) (0.5 * $table-cell-padding-x);
    }
  }
}

tr.border-top-double {
  > td {
    border-top-style: double !important;
  }
}
