.action-box {
  background-color: #fff;
  margin-bottom: 24px;
  position: relative;
  border-radius: 5px;
  border: 1px solid #ababab;
  min-height: 50px;

  .action-required-label {
    background-color: #039be5;
    top: -1px;
    position: absolute;
    left: 24px;
    padding-left: 8px;
    padding-right: 8px;
    text-transform: uppercase;
    color: #fff;
    font-size: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    &.muted {
      background-color: #949496;
      text-transform: none;
    }

    &.bg-green {
      background-color: #52a79e;
    }

    &.bg-yellow {
      background-color: #ffa200;
    }
  }

  &.warning {
    background-color: #ffffe0;
  }

  .content {
    padding: 24px;

    .header {
      margin-top: 14px;
      font-size: 23px;
    }

    .sub-header {
      margin-top: 14px;
      color: #949496;
      font-weight: 400;
      font-size: 16px;
    }

    .actions {
      .decision-btn {
        width: 150px;
        padding-top: 5px;
        padding-bottom: 5px;
        position: relative;

        &.btn-wide {
          width: 200px;
        }

        &.btn-wider {
          width: 300px;
        }

        mat-icon {
          position: absolute;
          margin-top: auto;
          top: 10px;
          left: 15px;
        }
      }
    }
  }

  .additional-content {
    border-top: thin solid #ababab;
  }
}
