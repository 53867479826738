@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('roboto-v18-greek_latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
       url('roboto-v18-greek_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('roboto-v18-greek_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('roboto-v18-greek_latin-300.woff') format('woff'), /* Modern Browsers */
       url('roboto-v18-greek_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('roboto-v18-greek_latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('roboto-v18-greek_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('roboto-v18-greek_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('roboto-v18-greek_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('roboto-v18-greek_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('roboto-v18-greek_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('roboto-v18-greek_latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('roboto-v18-greek_latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('roboto-v18-greek_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('roboto-v18-greek_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('roboto-v18-greek_latin-500.woff') format('woff'), /* Modern Browsers */
       url('roboto-v18-greek_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('roboto-v18-greek_latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('roboto-v18-greek_latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('roboto-v18-greek_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('roboto-v18-greek_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('roboto-v18-greek_latin-700.woff') format('woff'), /* Modern Browsers */
       url('roboto-v18-greek_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('roboto-v18-greek_latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
